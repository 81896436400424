<template>
  <div>
   <svg xmlns="http://www.w3.org/2000/svg" :height="size" :width="size" viewBox="0 0 36 36">
     <defs>
     </defs>
      <g transform="translate(0 0.378)">
        <rect class="a" width="36" height="36" transform="translate(0 -0.378)"/>
        <g transform="translate(3.333)">
            <path class="b" d="M28.407,24.792a9.819,9.819,0,0,1-3.487-7.513V13.193a10.269,10.269,0,0,0-8.8-10.144V1.466a1.466,1.466,0,0,0-2.932,0V3.049A10.268,10.268,0,0,0,4.4,13.193v4.087A9.829,9.829,0,0,1,.9,24.8a2.565,2.565,0,0,0,1.668,4.514H26.752a2.565,2.565,0,0,0,1.655-4.525Zm0,0" transform="translate(0)"/>
            <path class="b" d="M140.339,452.4a5.506,5.506,0,0,0,5.386-4.4H134.953A5.506,5.506,0,0,0,140.339,452.4Zm0,0" transform="translate(-125.68 -417.217)"/>
        </g>
            <circle class="fill" cx="5.5" cy="5.5" r="5.5" transform="translate(22 1.622)"/>
        </g>
   </svg>
  </div>
</template>

<script>
export default {
  props:['size']
};
</script>

<style>
.a{fill:none;}
.b{fill:#f7f7f7;}
</style>